<template>
	<div style="display: flex;align-items:stretch;height:100%;">
		<div id="container" style="flex-grow: 1;">
			<canvas>
			</canvas>
			<div style="position: absolute;bottom:0;display: flex;">
				<img v-for="(HDR, index) in HDRs" :src="`textures/equirectangular/${HDR}.jpg`"
					:style="`margin:0 0 50px 50px;border-radius:50%;width:100px;height:100px;cursor:pointer;border:2px solid ${index==hdrIndex?'#f80':'#fff'}`"
					:key="index" @click="changeHDR(index)">
			</div>
		</div>
		<div class="right-part">
			<div class="tabbar">
				<div class="tab-item" :class="{ 'actived': tabIndex === 0 }" @click="tabIndex = 0">
					<span>车款</span>
				</div>
				<div class="tab-item" :class="{ 'actived': tabIndex === 1 }" @click="tabIndex = 1">
					<span>外观</span>
				</div>
			</div>
			<div class="car-type" v-if="tabIndex === 0">
				<div class="list">
					<div class="list-item">
						<p>S4 Limousine </p>
						<p> ¥ {{ format(price) }} </p>
						<div class="icon">
							<img src="image/warning.png" alt="">
						</div>
					</div>
				</div>
			</div>
			<div class="car-type" v-if="tabIndex === 1">
				<div class="tips-line">
					<span @click="toAnchor('color')">车漆颜色</span>
					<span @click="toAnchor('tire')">轮毅</span>
					<span @click="toAnchor('carbody')">车身</span>
				</div>
				<div class="inner-info">
					<div class="title" id="color">
						车漆颜色
					</div>
					<div class="color-wrapper">
						<div class="color-item" :class="{ 'actived': index === colorIndex }" @click="changeColor(index)"
							v-for="(item, index) in colorList" :key="index">
							<div class="img-wrapper">
								<img :src="item.img" alt="">
							</div>
							<div class="color-name">
								{{ item.name }}
							</div>
							<div class="color-desc">
								{{ item.desc }}
							</div>
						</div>
					</div>
					<div class="title" id="tire">
						轮毅
					</div>
					<div class="tire-wrapper">
						<div class="tire-item" v-for="(item, index) in tireList" :key="index" @click="changeTire(index)"
							:class="{ 'actived': index === tireIndex }">
							<img :src="item.img" alt="">
							<div class="info">
								<div class="tire-name">{{ item.name }}</div>
								<div class="tire-info">
									<div class="tire-price" v-if="item.price">¥ {{ format(item.price) }}</div>
									<div v-else class="tire-price-include">价格已包含</div>
									<div class="tire-desc">{{ item.desc }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="title" id="carbody">
						车身
					</div>
					<div class="tire-wrapper">
						<div v-for="(item, index) in bodyList" :key="index">
							<div class="group-title">{{ item.title }}</div>
							<div class="tire-item" v-for="(inner, inx) in item.children" :key="inx"
								@click="activeChange(index, inner, item.children)"
								:class="{ 'actived': inner.actived }">
								<div class="info">
									<div class="tire-name">{{ inner.name }}</div>
									<div class="tire-info">
										<div class="tire-price" v-if="inner.price">¥ {{ format(inner.price) }}</div>
										<div v-else class="tire-price-include">价格已包含</div>
										<div class="tire-desc">{{ inner.desc }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="cac-total">
				<div class="pre-total">
					<div class="total-price">
						<span>¥ {{ format(totalFee) }} </span>
						<div class="more-icon" :style="{ transform: showSummary ? 'rotate(180deg)' : 'rotate(0deg)' }"
							@click="showSummary = true">
							<img src="image/arrowTop.png" alt="">
						</div>
					</div>
					<p>预估价格，请以正式发布价为准</p>
				</div>
				<div class="handle-btns">
					<div class="pre-step" v-if="tabIndex === 1" @click="tabIndex = 0">
						上一步
					</div>
					<div class="next-step" @click="() => { tabIndex === 1 ? showSummary = true : tabIndex = 1; }">
						下一步
					</div>
				</div>
			</div>
		</div>
		<transition enter-active-class="animate__animated animate__fadeIn"
			leave-active-class="animate__animated animate__fadeOut">
			<div class="box-shadow animate__animated" v-show="showSummary" @click="showSummary = false"></div>
		</transition>
		<transition enter-active-class="animate__animated animate__slideInUp"
			leave-active-class="animate__animated animate__slideOutDown">
			<div class="animate__animated" id="box" v-show="showSummary">
				<div id="summary">
					<div id="content">
						<h3 style="font-size: 24px;padding-bottom: 30px;padding-top: 10px;">S4 Limousine</h3>
						<fieldset>
							<div>
								<label>车辆总价</label>
								<span>预计 <span style="font-weight: 600;font-size: 16px;">￥{{ format(totalFee)
										}}</span></span>
							</div>
							<hr>
							<div>
								<label>标配价格</label>
								<span style="font-size: 16px;">预计 ￥{{ format(price) }}</span>
							</div>
							<div style="margin-top:18px;">
								<label>选配价格</label>
								<span style="font-size: 16px;"> ￥{{ format(fee) }}</span>
							</div>
							<hr>
						</fieldset>
						<fieldset>
							<legend style="padding-bottom:10px;padding-top:40px;font-size:18px;">已选装备-外观</legend>
							<hr>
							<div class="section-field">
								<div class="img-wrapper" style="margin-right:15px;">
									<img :src="colorList[colorIndex].img" alt="">
								</div>
								<label>{{ colorList[colorIndex].name }}</label>
								<span class="fieldset-span"> {{ colorList[colorIndex].price ?
									`￥${format(colorList[colorIndex].price)}` : "价格已包含"
									}}</span>
							</div>
							<hr>
							<div class="section-field">
								<div class="img-wrapper" style="margin-right:15px;">
									<img :src="tireList[tireIndex].img" alt="">
								</div>
								<label>{{ tireList[tireIndex].name }}</label>
								<span class="fieldset-span" :style="tireList[tireIndex].price ? 'color:#000;' : ''"> {{
									tireList[tireIndex].price ? `￥${format(tireList[tireIndex].price)}` : "价格已包含"
								}}</span>
							</div>
							<hr>
							<!--
              <div>
                <label>红色刹车卡钳 标配</label>
                <span>价格已包含</span>
              </div>
              <hr>
              <div>
                <label>透明玻璃 标配</label>
                <span>价格已包含</span>
              </div>
              <hr>
              -->
							<section v-for="(body, index) in bodyList" :key="index">
								<section v-for="(child, idx) in body.children" :key="idx">
									<template v-if="child.actived">
										<div class="section-field">
											<label>{{ child.name }}</label>
											<span class="fieldset-span" :style="child.price ? 'color:#000;' : ''"> {{
												child.price ? `￥${format(child.price)}` :
													"价格已包含" }}</span>
										</div>
										<hr>
									</template>
								</section>
							</section>
						</fieldset>
					</div>
					<div id="bar">
						<div class="pre-total" style="margin:5px;">
							<div class="total-price" style="font-size: 24px;font-weight:bold;">
								<span>¥ {{ format(totalFee) }} </span>
							</div>
							<p>预估价格，请以正式发布价为准</p>
						</div>
						<div class="handle-btns">
							<div class="pre-step" style="width:167px;margin-right:30px;" @click="showSummary = false">
								继续配置</div>
							<div class="next-step" style="width:167px;">去下单</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>
<script>

import * as THREE from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GroundedSkybox } from 'three/examples/jsm/objects/GroundedSkybox.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';


const SERVER = process.env.NODE_ENV === 'development' ? "" : "https://earth-online.oss-cn-beijing.aliyuncs.com/car/"
//console.error(process.env, SERVER)
//const isMobile = false || window.innerHeight > window.innerWidth
const tires = {}
const hubs = {}

const hdrLoader = new RGBELoader();
var scene, skybox
var HDRs = {}
export default {
	data() {
		return {
			showSummary: false,
			otherFee: 0,
			price: 488800,
			tabIndex: 0,
			hdrIndex:0,
			HDRs: [
				"driving_school_4k",
				"tief_etz_4k",
			],
			colorList: [
				{ name: '曜夜绿', desc: '价格已包含', img: 'image/M4M4.jpg', color: "#3B6141" },
				{ name: '冰川白', desc: '价格已包含', img: 'image/2Y2Y.jpg', color: "#ffffff" },
				{ name: '天云灰', desc: '价格已包含', img: 'image/6Y6Y.jpg', color: "#575d69" },
				{ name: '阿斯卡里蓝', desc: '价格已包含', img: 'image/9W9W.jpg', color: "#374778" },
				{ name: '传奇黑', desc: '价格已包含', img: 'image/0E0E.jpg', color: "#303030" },
				{ name: '纳瓦拉蓝', desc: '价格已包含', img: 'image/2D2D.jpg', color: "#4277b9" },
				{ name: '星辰灰', desc: '价格已包含', img: 'image/Z727.jpg', color: "#6a6b6f" }
			],
			colorIndex: 1,
			tireList: [
				{ name: '奥迪运动19寸5辐轮毂', img: 'image/44S.jpg', type: "A", price: 0, desc: '' },
				{ name: '奥迪运动19寸5辐V型星型泰坦黑轮毂', img: 'image/47E.jpg', type: "B", price: 1500, desc: '本地区40%的用户选择' }
			],
			tireIndex: 0,
			bodyList: [
				{
					title: '卡钳', children: [
						{ name: '红色刹车卡钳', price: '', desc: '标配', actived: true, ignore: true },
					]
				},
				{
					title: '玻璃', children: [
						{ name: '透明玻璃', price: '', desc: '标配', actived: true, ignore: true },
					]
				},
				{
					title: '外饰包', children: [
						{ name: '奥迪黑色外观套件', price: 0, desc: '标配', actived: true, color: "#fff" },
						{ name: '奥迪扩展黑色外观套件(带黑色logo)', price: 3500, desc: '本地区40%的用户选择', actived: false, color: "#000" },
					]
				},
				{
					title: '前排LED迎宾灯（带logo）', children: [
						{ name: '前排LED迎宾灯（带logo）', price: 1800, desc: '本地区40%的用户选择', actived: false },
					]
				}
			],
		}
	},
	methods: {
		changeHDR(index) {
			this.hdrIndex = index
			const name = this.HDRs[index]
			const params = {
				height: 25,
				radius: 200,
				enabled: true,
			};

			const envMap = HDRs[name]
			if (!skybox) {
				skybox = new GroundedSkybox(envMap, params.height, params.radius);
				skybox.position.y = params.height - 0.01;
				scene.add(skybox);
				scene.environment = envMap;
			} else {
				skybox.material.map = envMap
				scene.environment = envMap;
				this.render()
			}

		},
		format(num) {
			return num.toString().replace(/\d+/, function (n) {
				return n.replace(/(\d)(?=(\d{3})+$)/g, function (m) {
					return m + ',';
				});
			});
		},
		activeChange(index, item, children) {
			if (item.ignore) {
				return
			}
			if (children && children.length > 1) {
				children.forEach((inner) => {
					if (inner.actived) {
						this.otherFee -= inner.price
					}
					inner.actived = false
				})
				this.$set(item, 'actived', true)
				this.otherFee += item.price
				if (index == 2) {
					this.setColor("Audi_logo_front", item.color)
					this.setColor("SideMirrorrCase001", item.color)
				}
			} else {
				this.$set(item, 'actived', !item.actived)
				if (item.actived) {
					this.otherFee += item.price
				} else {
					this.otherFee -= item.price
				}
			}
		},
		changeColor(index) {
			this.colorIndex = index
			const color = this.colorList[index].color
			this.setColor("body", color)
		},
		toAnchor(id) {
			const anchor = document.getElementById(id);
			const innerInfoRef = document.querySelector('.inner-info');
			innerInfoRef.scrollTo({
				top: anchor.offsetTop - 100,
				behavior: 'smooth'
			});
		},
		changeTire(index) {
			this.tireIndex = index
			const type = this.tireList[index].type
			Object.values(tires).forEach(tire => {
				tire.visible = false
			})
			Object.values(hubs).forEach(hub => {
				hub.forEach(hb => {
					hb.visible = false
				})
			})
			//
			tires[type].visible = true
			hubs[type].forEach(hb => {
				hb.visible = true
			})
			this.render()
		}
	},
	computed: {
		totalFee() {
			return this.price + this.fee
		},
		fee() {
			return this.tireList[this.tireIndex].price + this.otherFee
		}
	},
	mounted() {
		const container = document.getElementById('container');
		const canvas = document.getElementsByTagName('canvas')[0];
		function getCanvasSize() {
			const result = { width: container.offsetWidth, height: container.offsetHeight }
			return result
		}
		const that = this


		let camera, renderer;
		var carModel
		init().then(render);

		async function init() {
			const { width, height } = getCanvasSize()

			camera = new THREE.PerspectiveCamera(
				40,
				width / height,
				1,
				1000
			);
			camera.position.set(- 20, 7, 20);
			camera.lookAt(0, 4, 0);

			scene = new THREE.Scene();
			////////////////////////////////



			//
			const root = new THREE.Group()
			scene.add(root)
			root.scale.multiplyScalar(2.5);
			///////////////////////////////////////////

			renderer = new THREE.WebGLRenderer({ antialias: true, canvas });
			renderer.setPixelRatio(window.devicePixelRatio);
			//renderer.toneMapping = THREE.NoToneMapping//ACESFilmicToneMapping;
			//renderer.toneMappingExposure = 1.5
			//renderer.toneMapping = THREE.NoToneMapping// 关闭色调映射//renderer.toneMapping = THREE.LinearToneMapping //没用
			renderer.toneMapping = THREE.ReinhardToneMapping //防光，不黑
			//renderer.toneMapping =THREE.CineonToneMapping //乌漆嘛黑
			// renderer.toneMapping，= THREE.ACESFilmicToneMapping //乌漆嘛黑
			//renderer.toneMapping = THREE.AgXToneMapping //乌漆嘛黑
			//renderer.toneMapping = THREE.NeutralToneMapping //乌漆嘛黑

			//

			const controls = new OrbitControls(camera, renderer.domElement);
			controls.addEventListener('change', render);
			controls.target.set(0, 2, 0);
			controls.maxPolarAngle = THREE.MathUtils.degToRad(80);
			controls.maxDistance = 40;
			controls.minDistance = 15;
			controls.enablePan = false;
			controls.enableZoom = true;
			controls.update();

			window.addEventListener('resize', onWindowResize);
			onWindowResize()
			////////////////////////////////////
			const dracoLoader = new DRACOLoader();
			dracoLoader.setDecoderPath(SERVER + 'jsm/libs/draco/gltf/');

			const loader = new GLTFLoader();
			loader.setDRACOLoader(dracoLoader);
			loader.load(SERVER + 'rs7.glb', async function (gltf) {
				carModel = gltf.scene
				carModel.position.y = -0.024
				//
				hubs.A = [carModel.children[2], carModel.children[3]]
				//
				tires.A = carModel.children[1]
				//
				root.add(carModel);
				//
				setColor("body", that.colorList[that.colorIndex].color)
				//
				setColor("Audi_logo_front", that.bodyList[2].children[0].color)
				setColor("SideMirrorrCase001", that.bodyList[2].children[0].color)

				function loadHDR(i) {
					const HDR = that.HDRs[i]
					hdrLoader.load(SERVER + `textures/equirectangular/${HDR}.hdr`, (envMap) => {
						envMap.mapping = THREE.EquirectangularReflectionMapping;
						HDRs[HDR] = envMap
						if (i == that.hdrIndex) {
							that.changeHDR(0)
							render()
						}
					});
				}
				for (var i =0;i< that.HDRs.length ;i++) {
					loadHDR(i)

				}

			});
			//
			loader.load(SERVER + 'shadow.glb', function (gltf) {
				const shadow = gltf.scene
				root.add(shadow);
			});
			loader.load(SERVER + 'tire.glb', function (gltf) {
				const tire = gltf.scene
				tires.B = tire
				tire.visible = false
				root.add(tire);
			});
			loader.load(SERVER + 'hub.glb', function (gltf) {
				const hub = gltf.scene
				hubs.B = [hub, hub]
				hub.visible = false
				root.add(hub);
			});
			/////////////////////////////
		}

		function onWindowResize() {

			//console.log('resize')
			const { width, height } = getCanvasSize()
			camera.aspect = width / height;
			camera.updateProjectionMatrix();

			renderer.setSize(width, height);

			render();

		}

		function render() {

			renderer.render(scene, camera);

		}
		this.render = render
		////////////////////////////////////////////////////
		function setColor(name, color) {
			//console.error(name)
			carModel.getObjectByName(name).material.color.set(color);
			render();
		}
		this.setColor = setColor
	}
}
</script>
<style>
/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
	font-family: AudiTypeWideNormal, DFPKingGothicGBRegular, sans-serif;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
	margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
	list-style: none;
}

/* Set core root defaults */
html:focus-within {
	scroll-behavior: smooth;
}

/* Set core body defaults */
body {
	min-height: 100vh;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
	max-width: 100%;
	display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	html:focus-within {
		scroll-behavior: auto;
	}

	*,
	*::before,
	*::after {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

:root {
	--animate-duration: 300ms;
	--animate-delay: 0s;
}

body {
	margin: 0;
	overflow: hidden;
	width: 100vw;
	height: 100vh;
}

#container {
	width: calc(100vw - 500px);
}

canvas {
	width: 100%;
	height: 100%;
}

#gui {
	color: #fff;
	width: 100%;
	margin-top: 50px;
	position: fixed;
	display: flex;
	align-items: center;
	flex-direction: column;
}

#info {
	display: flex;
	align-items: start;
	flex-direction: column;
}

.right-part {
	width: 500px;
	height: 100vh;
	background: #fff;
	padding: 0 20px;
}

.tabbar {
	display: flex;
	height: 80px;
	align-items: center;
}

.tab-item {
	width: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000;
	cursor: pointer;
	user-select: none;
}

.tab-item span {
	transition: all 0.5s;
}

.tab-item.actived span {
	font-weight: 600;
	border-bottom: 1px solid #000;
}

.list-item .icon img {
	opacity: 0.4;
	width: 18px;
	height: 18px;
}

.list-item {
	margin-bottom: 20px;
	border: 1px solid #bb0a30;
	padding: 20px;
	position: relative;
	cursor: pointer;
	color: #333;
}

.list-item .icon {
	position: absolute;
	top: 50%;
	right: 20px;
	transform: translateY(-50%);
}

.tips-line {
	border-top: 1px solid #e6e6e6;
	border-bottom: 1px solid #e6e6e6;
	color: #666;
	display: flex;
	height: 32px;
	justify-content: space-around;
	align-items: center;
}

.tips-line span {
	padding: 0 20px;
	font-size: 16px;
	cursor: pointer;
}

.inner-info {
	overflow-x: hidden;
	overflow-y: auto;
	height: calc(100vh - 80px - 32px);
	padding-bottom: 110px;
}

*::-webkit-scrollbar {
	/* width: 10px; */
	display: none;
}

*::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 5px;
}

.car-type .title {
	font-size: 24px;
	font-weight: 400;
	text-align: center;
	padding-bottom: 24px;
	padding-top: 36px;
}

.color-wrapper {
	display: flex;
	flex-wrap: wrap;
}

.group-title {
	color: #666;
	font-size: 18px;
	margin-bottom: 10px;
}


.img-wrapper {
	width: 68px;
	height: 68px;
	border: 1px solid transparent;
	padding: 3px;
	transition: all 0.2s;
}

.color-item.actived .img-wrapper {
	border: 1px solid #bb0a30;
}

.color-item.actived .color-name {
	color: #000;
}

.color-item.actived .color-desc {
	color: #555;
}

.img-wrapper img {
	width: 100%;
	height: 100%;
}

.color-item {
	width: 25%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-bottom: 20px;
	cursor: pointer;
	user-select: none;
}

.color-name {
	margin-top: 5px;
	color: #666;
	font-size: 16px;
}

.color-name,
.color-desc {
	font-weight: 400;
	line-height: 1.5;
}

.color-desc {
	color: #00000073;
	font-size: 15px;
}

.tire-item {
	border: 1px solid #E5E5E5;
	padding: 20px;
	display: flex;
	margin-bottom: 14px;
	cursor: pointer;
	user-select: none;
}

.tire-item img {
	width: 72px;
	height: 72px;
	margin-right: 20px;
}

.tire-item .info {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.tire-name {
	color: #333;
	font-weight: 400;
	font-size: 16px;
}

.tire-info {
	display: flex;
	margin-top: 4px;
	align-items: center;
}

.tire-price-include {
	color: #00000073;
	font-weight: 400;
	font-size: 14px;
}

.tire-price {
	font-size: 16px;
	color: #333;
}

.tire-desc {
	color: #666;
	padding: 0 8px;
	width: auto;
	margin-left: 15px;
	background: rgba(0, 0, 0, .05);
	border: 0;
	line-height: 20px;
	font-size: 12px;
	display: inline-block;
	font-weight: 300;
}

.tire-item.actived {
	border: 1px solid #bb0a30;
}

.cac-total {
	position: absolute;
	bottom: 0px;
	right: 0px;
	width: 500px;
	height: 100px;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
}

.pre-total p {
	font-size: 12px;
	color: rgb(102, 102, 102);
}

.cac-total .total-price {
	display: flex;
	align-items: center;
	font-size: 24px;
	font-weight: 600;
}

.handle-btns {
	display: flex;
}

.handle-btns .pre-step,
.handle-btns .next-step {
	width: 111px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	cursor: pointer;
	user-select: none;
}

.handle-btns .pre-step:hover,
.handle-btns .next-step:hover {
	filter: brightness(1.1);
}


.handle-btns .pre-step {
	border: 1px solid #000;
}

.handle-btns .next-step {
	border: 1px solid #BB0A30;
	background: #BB0A30;
	color: #fff;
	margin-left: 10px;
}

.more-icon {
	width: 24px;
	height: 24px;
	margin-left: 6px;
	cursor: pointer;
	transform: rotate(0deg);
}

.more-icon img {
	width: 100%;
	height: 100%;
}
</style>
<style>
html,
body {
	height: 100%;
}

#box {
	display: flex;
	align-items: center;
	flex-direction: column;
	height: 100%;
	top: 0;
	width: 960px;
	left: calc(50% - 480px);
	position: fixed;
	z-index: 2;
}

.box-shadow {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 1;
}

#summary {
	display: flex;
	height: 100%;
	top: 0;
	width: 100%;
	flex-direction: column;
	background: #fff;
}

#summary>div {
	margin: 0 60px
}

#summary #content {
	overflow-y: scroll;
	flex-grow: 1;
}

#summary #content fieldset {
	padding: 0;
	margin-bottom: 10px;
	border: none;
}

#summary #content fieldset label {
	color: #000c;
}


#summary #content .fieldset-span {
	color: #999;
	font-weight: 400;
	font-size: 14px;
}

#summary .section-field {
	min-height: 48px;
	display: flex;
	align-items: center;
}

#summary #content fieldset hr {
	height: 1px;
	border: none;
	background: #E5E5E5;
}

#summary #content fieldset legend {
	font-weight: bold;
	width: 100%;
	border: none;
}

#summary #content fieldset div {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#summary #content fieldset div span {
	flex-grow: 1;
	text-align: right;
}

#summary #bar {
	height: 100px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
</style>